import React, { Component } from "react";
import "./LayerRevealMobile.css";
import layerOne from "../../assets/AboutUs/layersMobile/1.png";
import layerTwo from "../../assets/AboutUs/layersMobile/2.png";
import layerThree from "../../assets/AboutUs/layersMobile/3.png";
import layerFour from "../../assets/AboutUs/layersMobile/4.png";
import { Controller, Scene } from "react-scrollmagic";
import { Tween } from "react-gsap";

class LayerRevealMobile extends Component {
  render() {
    return (
      <div>
        <div id="trigger" />
        <Controller>
          <Scene triggerElement="#trigger" duration={600}>
            {(progress) => (
              <div className="row justify-content-center position-relative layer-mobile-reveal">
                <Tween
                  from={{
                    y: 143,
                  }}
                  totalProgress={progress}
                  paused
                >
                  <img
                    src={layerFour}
                    className="position-absolute layer-mobile-four"
                    alt=""
                  />
                </Tween>
                <Tween
                  from={{
                    y: 143,
                  }}
                  totalProgress={progress}
                  paused
                >
                  <img
                    src={layerThree}
                    className="position-absolute layer-mobile-three"
                    alt=""
                  />
                </Tween>
                <Tween
                  from={{
                    y: 143,
                  }}
                  totalProgress={progress}
                  paused
                >
                  <img
                    src={layerTwo}
                    className="position-absolute layer-mobile-two"
                    alt=""
                  />
                </Tween>
                <Tween
                  from={{
                    y: 143,
                  }}
                  totalProgress={progress}
                  paused
                >
                  <img
                    src={layerOne}
                    className="position-absolute layer-mobile-one"
                    alt=""
                  />
                </Tween>
              </div>
            )}
          </Scene>
        </Controller>
      </div>
    );
  }
}

export default LayerRevealMobile;
