import React, { Component } from "react";
import { Controller, Scene } from "react-scrollmagic";
import { Tween } from "react-gsap";
import "./AboutUs.css";
import LayerReveal from "../../components/layerReveal/layerReveal";
import LayerRevealMobile from "../../components/LayerRevealMobile/LayerRevealMobile";
import { Button } from "evergreen-ui";

const yOffset = -30;
const xOffset = 80;
const landingOffset = 125;
const duration = 4;
const LayerChange = ({ width }) => {
  console.log(width);

  if (width > 600) {
    return (
      <div className="container padding-b-120 layer-reveal-div">
        <LayerReveal />
        <div className="left-tag col-2 tag-1 position-absolute">
          <hr className="mt-0" />
          <div class="tag-dot float-right"></div>
          <h4 id="trig1" className="f-subtitle semi-bold">
            Connects with Your Bank Account
          </h4>
          <p>
            Coinup is compatible with over 10,000+ financial institutions
            worldwide
          </p>
        </div>
        <div className="left-tag col-2 tag-2 position-absolute">
          <hr className="mt-0" />
          <div class="tag-dot float-right"></div>
          <h4 id="trig3" className="f-subtitle semi-bold">
            You control your assets
          </h4>
          <p>
            We integrate with your Coinbase account so you have a transparent
            view of your investment portfolio
          </p>
        </div>

        <div className="row justify-content-end ">
          <div className="left-tag col-2 tag-3 position-absolute ">
            <hr className="mt-0" />
            <div class="tag-dot float-left"></div>
            <h4 id="trig2" className="f-subtitle semi-bold text-right">
              Invest, automatically
            </h4>
            <p className="text-right">
              Watch your money grow by investing change on a weekly recurring
              basis
            </p>
          </div>
        </div>

        <div className="row justify-content-end ">
          <div className="left-tag col-2 tag-4 position-absolute ">
            <hr className="mt-0" />
            <div class="tag-dot float-left"></div>
            <h4 id="trig4" className="f-subtitle semi-bold text-right">
              Crypto for everyone
            </h4>
            <p className="text-right">Start investing with as little as $1</p>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="container layerMobile-reveal-div">
        <LayerRevealMobile />
      </div>
    );
  }
};

class AboutUs extends Component {
  constructor(props) {
    super(props);
    document.documentElement.setAttribute("data-theme", "light");
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    return (
      <div className="overflow-x-hidden overflow-y-hidden">
        <div className="about-landing">
          <div className="row align-items-center h-100">
            <div className="container h-100">
              <div className="row justify-content-between align-items-center h-100">
                <div className="px-5 px-md-0 col-md-5 position-relative order-2 order-md-1 about-landing-text mt-md-0">
                  <h2 className="f-header">
                    Your first step into
                    <br /> crypto markets.
                  </h2>
                  <p>
                    Coinup helps everyday users invest in mainstream crypto
                    markets by rounding up your everyday transactions and
                    investing the total.
                  </p>
                  <a href="https://my.coinupapp.ca/signup">
                    <Button appearance="primary" marginRight={16}>
                      Get started
                    </Button>
                  </a>
                  {/* <Button appearance="minimal" className="minimalButton">
                    Learn more
                  </Button> */}
                </div>
                <div className="col-md-7 order-1 order-md-2">
                  <Tween
                    from={{ y: yOffset, x: xOffset }}
                    to={{
                      y: yOffset + landingOffset,
                      x: xOffset - landingOffset,
                    }}
                    ease="back.out(2)"
                    duration={duration}
                  >
                    <img
                      className="landing-img"
                      src={require("../../assets/AboutUs/landing/redBlur.png")}
                      alt=""
                    />
                  </Tween>
                  <Tween
                    from={{ y: yOffset, x: xOffset }}
                    to={{
                      y: yOffset - landingOffset,
                      x: xOffset + landingOffset,
                    }}
                    ease="back.out(2)"
                    duration={duration}
                  >
                    <img
                      className="landing-img"
                      src={require("../../assets/AboutUs/landing/blueBlur.png")}
                      alt=""
                    />
                  </Tween>
                  <Tween from={{ y: 25 }} duration={duration - 2}>
                    <img
                      className="col something-colorful"
                      src={require("../../assets/AboutUs/landing/home.png")}
                      alt=""
                    />
                  </Tween>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="about" className="about-roots position-relative">
          <div className="text-center margin-t-120">
            <h3 className="f-subtitle">Breaking down the layers</h3>
            <h2 className="f-title">Why Coinup?</h2>
          </div>

          {/* Shapes */}
          <div className="about-us-shapes">
            <Controller>
              <Scene triggerElement="#trig1" duration={1000}>
                {(progress) => (
                  <Tween
                    to={{
                      x: -50,
                      y: 0,
                    }}
                    ease="none"
                    totalProgress={progress}
                    paused
                  >
                    <div class="circle-2 position-absolute"></div>
                  </Tween>
                )}
              </Scene>
              <Scene triggerElement="#trig2" duration={2400}>
                {(progress) => (
                  <Tween
                    to={{
                      y: 2600,
                      x: -350,
                    }}
                    ease="none"
                    totalProgress={progress}
                    paused
                  >
                    <div class="circle-3 position-absolute"></div>
                  </Tween>
                )}
              </Scene>
            </Controller>
          </div>
          <LayerChange width={this.state.width} />
        </div>
        <div className="about-core">
          <div className="text-center margin-t-120">
            <h3 className="f-subtitle">The Process</h3>
            <h2 id="trig5" className="f-title">
              How does it work?
            </h2>
          </div>
          <div className="container margin-t-70 padding-b-120">
            <div className="row justify-content-center">
              <div className="col-md col-10 text-center core-card">
                <h4 className="f-subtitle bold core-title pt-3">
                  Connect your accounts
                </h4>
                <div className="row justify-content-center core-desc">
                  <p className="col-md-9 col-11">
                    Simply and securely link your bank and crypto accounts
                    through our Plaid and Coinbase integrations.
                  </p>
                </div>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md col-10 text-center core-card mt-5 mt-md-0">
                <h4 className="f-subtitle bold core-title pt-3">
                  Select your investment preferences
                </h4>
                <div className="row justify-content-center core-desc">
                  <p className="col-md-9 col-11">
                    We'll help you choose a risk level you're comfortable with
                    and invest based on your financial goals
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center core-row">
              <div className="col-md col-10 text-center core-card">
                <h4 className="f-subtitle bold core-title pt-3">Spend money</h4>
                <div className="row justify-content-center core-desc">
                  <p className="col-md-9 col-11">
                    When you make purchases, we will round up the transaction
                    and provide you with meaningful insights in realtime.
                  </p>
                </div>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md col-10 text-center core-card mt-5 mt-md-0">
                <h4 className="f-subtitle bold core-title pt-3">Invest</h4>
                <div className="row justify-content-center core-desc">
                  <p className="col-md-9 col-11">
                    At the end of the week, we will total up all the round-up
                    amounts and use your Coinbase account to invest in Crypto
                    markets.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* 
        <div className="about-team margin-t-120">
          <div className="text-center">
            <h3 className="f-subtitle">A team with grit</h3>
            <h2 className="f-title">The Flavus Five</h2>
          </div>
          <div className="container margin-t-70">
            <div className="row justify-content-md-between justify-content-center">
              <div className="col-10 col-md text-center">
                <div className="row justify-content-center">
                  <img
                    src={require("../../assets/AboutUs/team/dwarkesh.png")}
                    className="col-10 team-img"
                  />
                </div>
                <h4 className="team-name mt-4">Dwarkesh Amin</h4>
                <p className="team-title mt-2">President & Creative Director</p>
              </div>
              <div className="col-10 col-md text-center">
                <div className="row justify-content-center">
                  <img
                    src={require("../../assets/AboutUs/team/harsh.png")}
                    className="col-10 team-img"
                  />
                </div>
                <h4 className="team-name mt-4">Harsh Patel</h4>
                <p className="team-title mt-2">Head of Technology</p>
              </div>
              <div className="col-10 col-md text-center">
                <div className="row justify-content-center">
                  <img
                    src={require("../../assets/AboutUs/team/shiv.png")}
                    className="col-10 team-img"
                  />
                </div>
                <h4 className="team-name mt-4">Shiv Patel</h4>
                <p className="team-title mt-2">Head of Operations</p>
              </div>
              <div className="col-10 col-md text-center">
                <div className="row justify-content-center">
                  <img
                    src={require("../../assets/AboutUs/team/parin.png")}
                    className="col-10 team-img"
                  />
                </div>
                <h4 className="team-name mt-4">Parin Shah</h4>
                <p className="team-title mt-2">Head of Business Development</p>
              </div>
              <div className="col-10 col-md text-center">
                <div className="row justify-content-center">
                  <img
                    src={require("../../assets/AboutUs/team/sharan.png")}
                    className="col-10 team-img"
                  />
                </div>
                <h4 className="team-name mt-4">Sharan Patel</h4>
                <p className="team-title mt-2">Web Developer</p>
              </div>
            </div>
          </div>
          <div className="container ">
            <p className="appendix ml-3">
              <sup>1</sup>Memojis
            </p>
          </div>
          <div className="row justify-content-center mt-4">
            <FButtonPill value="Hire the team" style="dark" href="contact" />
          </div>
        </div>
        <div className="about-ffp margin-t-120 padding-b-120" id="ffp">
          <div className="text-center">
            <h3 className="f-subtitle">Become a winner today and join</h3>
            <h2 className="f-title">
              The Flavus
              <br />
              Freelance Program
            </h2>
            <div className="row justify-content-center">
              <p className="col-10 col-md-5 mt-5">
                The Flavus Freelance Program is the new bridge between
                freelancers and clients! This program will give you access to a
                various design and media related tasks as well as mentorship
                from experienced creatives to help you strengthen your
                portfolio.
              </p>
            </div>
            <p className="mt-2">
              So hit the "Apply Today" button and unlock your creativity!
            </p>
          </div>
          <div className="row justify-content-center mt-5">
            <FButtonPill
              value="Apply Today"
              style="dark"
              href="https://form.jotform.com/flavusmedia/freelance-program"
              newTab={true}
            />
          </div>
        </div>
        <div className="container ">
          <p className="appendix ">
            1. Memojis designed by and property of Apple Inc. No copyright
            intended.
          </p>
        </div>  */}
      </div>
    );
  }
}

export default AboutUs;
