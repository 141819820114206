import React, { Component } from "react";
import "./Footer.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
//Adding Icons to Lib
library.add(fab);

class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <div className="container text-center text-md-left footer-content">
          <div className="navbar-logo-dark">
            <h3 className="footer-brand">Coinup</h3>
          </div>
          <hr className="footer-line" />
          <div className="row justify-content-center justify-content-md-between ">
            <p className="col-md-8 col-9">
              Designed & Developed by your friends at Coinup{" "}
              <span role="img" aria-label="wave">
                👋
              </span>
            </p>
            {/* <div className="col-md-2 col-6 row justify-content-between justify-content-md-between mr-md-0 mt-3 mt-md-auto">
              <a href="https://www.instagram.com/flavus.ca/" target="_blank">
                <FontAwesomeIcon
                  icon={["fab", "instagram"]}
                  size="md"
                  className="footer-icons"
                />
              </a>
              <a href="https://www.facebook.com/flavus.ca/" target="_blank">
                <FontAwesomeIcon
                  icon={["fab", "facebook-f"]}
                  size="md"
                  className="footer-icons"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/flavusmedia/"
                target="_blank"
              >
                <FontAwesomeIcon
                  icon={["fab", "linkedin-in"]}
                  size="md"
                  className="footer-icons"
                />
              </a>
              <a href="https://www.behance.net/dwarkeshamin" target="_blank">
                <FontAwesomeIcon
                  icon={["fab", "behance"]}
                  size="md"
                  className="footer-icons"
                />
              </a>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
