import React, { useEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import AboutUs from "./pages/AboutUs/AboutUs";

function Redirect() {
  const history = useHistory();

  useEffect(() => {
    history.push("/");
  }, [history]);

  return <div>Loading...</div>;
}

export default function Routes() {
  return (
    <Switch>
      {/* <Route path="/" exact component={HomePage} /> */}
      <Route path="/" exact component={AboutUs} />
      <Route path="*" component={Redirect} />
      {/* <Route path="/portfolio" exact component={Portfolio} />
      <Route path="/services" exact component={OurServices} />
      <Route path="/contact" exact component={Contact} /> */}
    </Switch>
  );
}
