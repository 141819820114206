import React, { Component } from "react";
import "./MyNavbar.css";
import { Navbar, Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Button } from "evergreen-ui";

console.log(document.documentElement.getAttribute("data-theme"));

class MyNavbar extends Component {
  render() {
    return (
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="none"
        variant="light"
        fixed="top"
        className="mynavbar py-md-3 px-md-5 px-3 py-2"
      >
        <LinkContainer to="/" className="navbar-logo-container">
          <Navbar.Brand>
            <div className="navbar-logo-light">
              <h3 className="nav-brand">Coinup</h3>
            </div>
            <div className="navbar-logo-dark">
              <h3 className="nav-brand">Coinup</h3>
            </div>
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          className="mynavbar-toggle"
        />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className=""></Nav>
          <Nav className="ml-auto">
            {/* <a
              href="https://my.coinupapp.ca/login"
              className="text-decoration-none"
            >
              <Button
                appearance="minimal"
                marginTop={4}
                className="minimalButton"
              >
                Sign in
              </Button>
            </a> */}
            <a href="https://my.coinupapp.ca/signup">
              <Button marginLeft={20} marginTop={4} appearance="primary">
                Sign up
              </Button>
            </a>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default MyNavbar;
