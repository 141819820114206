import React from "react";
import Routes from "./Routes";
import Footer from "./components/global/Footer/Footer";
import MyNavbar from "./components/global/MyNavbar/MyNavbar";
import { ThemeProvider } from "evergreen-ui";
import { defaultTheme } from "./theme";

function App() {
  return (
    <div className="App">
      <ThemeProvider value={defaultTheme}>
        <MyNavbar />
        <Routes />
        <Footer />
      </ThemeProvider>
    </div>
  );
}

export default App;
